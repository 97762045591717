export default [
    {
        text: 'EAN',
        value: 'ean',
    },
    {
        text: 'Title',
        value: 'title',
    },
    {
        text: 'Condition',
        value: 'condition',
    },
    {
        text: 'Unit Qty',
        value: 'baseQuantity',
        align: 'end',
        class: 'grouped-heading',
        cellClass: 'grouped-cell',
    },
    {
        text: 'Unit Price',
        value: 'unitPrice',
        align: 'end',
        class: 'grouped-heading',
        cellClass: 'grouped-cell',
    },
    {
        text: 'Unrcld Qty',
        value: 'qtyRemainderToReconcile',
        align: 'end',
    },
    {
        text: 'Unrcld Price',
        value: 'priceRemainderToReconcile',
        align: 'end',
    },
];
