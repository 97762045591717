<template>
    <v-form class="sidebar-container" @submit.prevent="postFiltersToGetRRAList()">
        <div class='logistics-section sidebar-container__section'>
            <div>
                <h3 class="sidebar-container__header">Supplier</h3>
                <span class="logistics-section__input">
                    <DropdownPrimarySupplier
                        v-model="primarySupplier"
                        outlined
                        :hideDetails="true"
                    />
                </span>
            </div>
            <div>
                <span class="sidebar-container__header">
                    <h3>EAN</h3>
                    <ToolTip class="tooltip">
                        <v-icon slot="target" class="tooltip__icon">mdi-information-outline</v-icon>
                        <div slot="content">
                            May increase search time
                        </div>
                    </ToolTip>
                </span>
                <v-text-field
                    v-model="isbn"
                    clearable
                    dense
                    hideDetails
                    type="text"
                    backgroundColor="white"
                    outlined
                    :rules="[rules.isbn]"
                />
            </div>
            <div>
                <h3 class="sidebar-container__header">Transaction Number</h3>
                <v-text-field
                    v-model="transactionNumber"
                    clearable
                    dense
                    hideDetails
                    type="text"
                    outlined
                    backgroundColor="white"
                />
            </div>
            <div>
                <h3 class="sidebar-container__header">Transaction Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="transactionDateStart"
                        :end-date.sync="transactionDateEnd"
                        label="Tranaction Date"
                        outlined
                    />
                </div>
            </div>
        </div>
        <div class="search-parameter sidebar-container__section logistics-section">
            <div>
                <h3 class="sidebar-container__header">RRA Number</h3>
                <v-text-field
                    v-model="rraNumber"
                    clearable
                    dense
                    hideDetails
                    type="text"
                    outlined
                    backgroundColor="white"
                />
            </div>
            <div>
                <h3 class="sidebar-container__header">RRA Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="rraDateStart"
                        :end-date.sync="rraDateEnd"
                        label="RRA Date"
                        outlined
                    />
                </div>
            </div>
            <div class="search-parameter status">
                <h3 class="sidebar-container__header">RRA Status:</h3>
                <CheckboxMultiSelectPersistable
                    v-model="rraStatusFilter"
                    :leftColumnKeys="leftColumnRRAStatusList"
                />
            </div>
        </div>

        <!-- PO Number Quick Search -->
        <div class="sidebar-container__section logistics-section">
            <div>
                <h3 class="sidebar-container__header">Credit Memo Number</h3>
                <v-text-field
                    v-model="creditMemoNumber"
                    clearable
                    dense
                    hideDetails
                    type="text"
                    outlined
                    backgroundColor="white"
                />
            </div>
            <div>
                <h3 class="sidebar-container__header">Credit Memo Date</h3>

                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="creditMemoDateStart"
                        :end-date.sync="creditMemoDateEnd"
                        label="Credit Memo Date"
                        outlined
                    />
                </div>
            </div>
            <!-- CREDIT MEMO STATUS -->
            <div
                id="status"
                class="search-parameter"
            >
                <h3 class="sidebar-container__header">Credit Memo Status:</h3>
                <CheckboxMultiSelectPersistable
                    v-model="creditMemoStatusFilter"
                    :leftColumnKeys="leftColumnCreditMemoStatusList"
                />
            </div>
        </div>

        <!-- PO Number Quick Search -->
        <div class="sidebar-container__section">
            <div>
                <div class="sidebar-container__header">
                    <h3>Title</h3>
                    <ToolTip class="tooltip">
                        <v-icon slot="target" class="tooltip__icon">mdi-information-outline</v-icon>
                        <div slot="content">
                            May increase search time
                        </div>
                    </ToolTip>
                </div>
                <v-text-field
                    v-model="title"
                    clearable
                    dense
                    hideDetails
                    type="text"
                    outlined
                    backgroundColor="white"
                />
            </div>
        </div>

        <!-- Action Buttons -->
        <div
            id="button-container"
            class="sidebar-container__section"
        >
            <v-btn
                outlined
                color="white"
                @click="clearFilters()"
            >
                Clear
            </v-btn>

            <v-btn
                class="button-search"
                type="submit"
            >
                Search
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import ValidationUtil from '@/utils/ValidationUtil';

// Components
import CheckboxMultiSelectPersistable from '@/components/base/multiselect/CheckboxMultiSelectPersistable';
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import ToolTip from '@/components/base/tooltip/ToolTip';

// Constants
import { CREDIT_MEMO_STATUS_LIST_COLUMN } from '@/constants/creditMemo/creditMemoStatusList';
import { RRA_STATUS_LIST_COLUMN } from '@/constants/creditMemo/rraStatusList';
import InputDateRange from '@/components/InputDateRange';

export default {
    name: 'SidebarFilterCreditMemos',
    components: {
        InputDateRange,
        CheckboxMultiSelectPersistable,
        DropdownPrimarySupplier,
        ToolTip,
    },
    data() {
        return {
            rules: {
                // Returns true if value is falsey OR passes ValidationUtil.isValidIsbn()
                isbn: ( value ) => ( !value || ValidationUtil.isValidIsbn( value ) ),
            },
        };
    },
    computed: {
        ...mapState( {
            filters: ( state ) => state.CreditMemo.filters,
            rraStatusList: ( state ) => state.CreditMemo.rraStatusList,
            creditMemoStatusList: ( state ) => state.CreditMemo.creditMemoStatusList,
        } ),
        leftColumnRRAStatusList() {
            return this.rraStatusList.filter( ( status ) => RRA_STATUS_LIST_COLUMN[ status ] === 0 );
        },
        leftColumnCreditMemoStatusList() {
            return this.creditMemoStatusList.filter( ( status ) => CREDIT_MEMO_STATUS_LIST_COLUMN[ status ] === 0 );
        },
        rraStatusFilter: {
            get() {
                return this.filters.rraStatusList;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterRRAStatusList', { rraStatusList: value } );
            },
        },
        creditMemoStatusFilter: {
            get() {
                return this.filters.creditMemoStatusList;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterCreditMemoStatusList', { creditMemoStatusList: value } );
            },
        },
        primarySupplier: {
            get() {
                return this.filters.primarySupplier;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterPrimarySupplier', { primarySupplier: value } );
            },
        },
        rraDateStart: {
            get() {
                return this.filters.rraDateStart;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterRRADateStart', { rraDateStart: value } );
            },
        },
        rraDateEnd: {
            get() {
                return this.filters.rraDateEnd;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterRRADateEnd', { rraDateEnd: value } );
            },
        },
        title: {
            get() {
                return this.filters.title;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterTitle', { title: value } );
            },
        },
        creditMemoDateStart: {
            get() {
                return this.filters.creditMemoDateStart;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterCreditMemoDateStart', { creditMemoDateStart: value } );
            },
        },
        creditMemoDateEnd: {
            get() {
                return this.filters.creditMemoDateEnd;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterCreditMemoDateEnd', { creditMemoDateEnd: value } );
            },
        },
        transactionDateStart: {
            get() {
                return this.filters.transactionDateStart;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterTransactionDateStart', { transactionDateStart: value } );
            },
        },
        transactionDateEnd: {
            get() {
                return this.filters.transactionDateEnd;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterTransactionDateEnd', { transactionDateEnd: value } );
            },
        },
        isbn: {
            get() {
                return this.filters.isbn;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterIsbn', { isbn: value } );
            },
        },
        transactionNumber: {
            get() {
                return this.filters.transactionNumber;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterTransactionNumber', { transactionNumber: value } );
            },
        },
        rraNumber: {
            get() {
                return this.filters.rraNumber;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterRRANumber', { rraNumber: value } );
            },
        },
        creditMemoNumber: {
            get() {
                return this.filters.creditMemoNumber;
            },
            set( value ) {
                this.$store.dispatch( 'CreditMemo/setFilterCreditMemoNumber', { creditMemoNumber: value } );
            },
        },
    },
    async beforeMount() {
        await this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        clearFilters() {
            this.$store.dispatch( 'CreditMemo/reset' );
        },
        postFiltersToGetRRAList() {
            this.$store.dispatch( 'CreditMemo/getByFilters' );
        },
    },
};
</script>

<style scoped lang="scss">
    .sidebar-container {
        &__header {
            display: flex;
            justify-content: space-between;
        }

        &__section>:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .status h3 {
        align-self: start;
    }

    #button-container {
        display: flex;
        justify-content: space-around;
        border-bottom: none;

        .button-search {
            color: var(--v-primary-base);
        }
    }

    .search-parameter {
        &__row {
            display: flex;
            align-items: center;
            .v-input {
                margin-top: 0;
                width: 165px;
            }
            p {
                margin: 0 0.5rem 0;
            }
        }
    }
</style>
