<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar />
        <ThePageHeadingBar
            :pageHeading="headerTitle"
            fullSize
            backArrow
            @goBack="$router.push( { name: 'CreditMemoReconciliationSearch' } )"
        />
        <section class="credit-memo-detail main">
            <ModalUpdateError
                v-model="showUpdateErrorModal"
                :inFlight="inFlight"
                updateActionPath="CreditMemo/Detail/update"
                defaultErrorMessage="Error updating Credit Memo."
            />
            <ModalConfirmation
                v-model="failedToFindCreditMemoDialog"
                confirmationTitle="Credit Memo not found"
                primaryButtonText="Okay"
                maxWidth="300px"
                hideCancelButton
                persistent
                @confirm="goToSearch()"
            />
            <v-card
                class="detail"
                :loading="inFlight"
            >
                <v-container fluid>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="detail__section"
                        >
                            <v-row class="detail__item">
                                <v-col cols="4">
                                    <h3>Supplier</h3>
                                </v-col>
                                <v-col cols="8">
                                    {{ info.supplier.supplierName }}
                                </v-col>
                            </v-row>
                            <v-row class="detail__item">
                                <v-col cols="4">
                                    Credit Memo Number
                                </v-col>
                                <v-col cols="8">
                                    {{ info.creditMemoNumber }}
                                </v-col>
                            </v-row>
                            <v-row class="detail__item">
                                <v-col cols="4">
                                    Credit Memo Date
                                </v-col>
                                <v-col cols="8">
                                    <ClDatePicker
                                        v-model="date"
                                        @blur="updateInfo()"
                                />
                                </v-col>
                            </v-row>
                            <v-row class="detail__item">
                                <v-col cols="4">
                                    Credit Memo Total
                                </v-col>
                                <v-col cols="8">
                                    <InputCurrency
                                        v-model="total"
                                        @blur="updateInfo()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="detail__item">
                                <v-col cols="4">
                                    <div>Notes</div>
                                    <div style="font-size: .75rem">( <a @click="showHistoryModal = true;">Historical</a> )</div>
                                </v-col>
                                <v-col cols="8">
                                    <v-textarea
                                        v-model="notes"
                                        outlined
                                        dense
                                        hideDetails
                                        @blur="updateInfo()"
                                    />
                                </v-col>
                                <ModalConfirmation
                                    v-model="showHistoryModal"
                                    confirmationTitle="Historical Notes"
                                    primaryButtonText="Ok"
                                    hideCancelButton
                                    headerClass="info"
                                    primaryButtonColor="info"
                                    cancelButtonColor="info"
                                    @confirm="showHistoryModal = false"
                                >
                                    <template #confirmationText>
                                        <v-textarea
                                            readonly
                                            :value="info.historicalNotes"
                                            outlined
                                            dense
                                            hideDetails
                                        >
                                        </v-textarea>
                                    </template>
                                </ModalConfirmation>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="detail__section"
                        >
                            <v-row class="detail__item">
                                <v-col cols="12">
                                    <h3>Adjustments</h3>
                                </v-col>
                                <v-col cols="12">
                                    <v-card>
                                        <v-container>
                                            <v-row class="adjustments__header grey lighten-3">
                                                <v-col cols="4"><h4>Type</h4></v-col>
                                                <v-col cols="4"><h4>(-)</h4></v-col>
                                                <v-col cols="4"><h4>(+)</h4></v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4">
                                                    Misc Adj
                                                </v-col>
                                                <v-col cols="4">
                                                    <InputCurrency
                                                        v-model="miscAdj"
                                                        @blur="updateInfo()"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="4">
                                                    Fees
                                                </v-col>
                                                <v-col offset="4" cols="4">
                                                    <InputCurrency
                                                        v-model="adjustmentFees"
                                                        @blur="updateInfo()"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            lg="4"
                            class="detail__section"
                        >
                            <v-row class="detail__item">
                                <v-col cols="3">
                                    <h3>Status</h3>
                                </v-col>
                                <v-col cols="9">
                                    <v-autocomplete
                                        v-model="documentStatus"
                                        outlined
                                        dense
                                        hideDetails
                                        :items="creditMemoStatusList"
                                        @blur="updateInfo()"
                                    />
                                </v-col>
                            </v-row>

                            <v-row class="detail__item">
                                <v-col cols="3">
                                    <h3>Totals</h3>
                                </v-col>
                                <v-col cols="9">
                                    <v-container class="totals">
                                        <v-row class="totals__row">
                                            <v-col cols="8">
                                                Calculated Line Total
                                            </v-col>
                                            <v-col cols="4" class="totals__item">
                                                {{ creditMemoLinesTotal }}
                                            </v-col>
                                        </v-row>
                                        <v-row class="totals__row totals__row--section-end">
                                            <v-col cols="8">
                                                Total Adjustments
                                            </v-col>
                                            <v-col cols="4" class="totals__item">
                                                {{ totalAdjustmentsDisplay }}
                                            </v-col>
                                        </v-row>
                                        <v-row class="totals__row">
                                            <v-col cols="8">
                                                Calculated Total
                                            </v-col>
                                            <v-col cols="4" class="totals__item">
                                                {{ calculatedTotal }}
                                            </v-col>
                                        </v-row>
                                        <v-row class="totals__row totals__row--section-end">
                                            <v-col cols="8">
                                                Credit Memo Total
                                            </v-col>
                                            <v-col cols="4" class="totals__item">
                                                {{ displayedTotal }}
                                            </v-col>
                                        </v-row>
                                        <v-row class="totals__row totals__row--difference">
                                            <v-col cols="8">
                                                Difference
                                            </v-col>
                                            <v-col cols="4" class="totals__item">
                                                {{ discrepancyDisplay }}
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <span class="detail__delete-button">
                    <v-spacer/>
                    <v-btn
                        :disabled="inFlight"
                        class="delete"
                        color="primary"
                        text
                        @click="deleteConfirmationDialog = true"
                    >
                        Delete
                    </v-btn>
                    <ModalConfirmation
                        v-model="deleteConfirmationDialog"
                        confirmationTitle="Delete Credit Memo"
                        confirmationText="Are you sure you want to delete the credit memo?"
                        primaryButtonText="Delete"
                        headerClass="info"
                        primaryButtonColor="info"
                        cancelButtonColor="info"
                        @confirm="deleteCreditMemo"
                    />
                </span>
            </v-card>
            <v-expansion-panels
                v-model="panels"
                class="expansion-panels"
                multiple
            >
                <v-expansion-panel class="unreconciled-purchase-orders">
                    <v-expansion-panel-header>
                        <h2>Return Transaction Lines</h2>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <ReturnTransactionLines
                            :isDirty.sync="isDirty"
                            @activator:click="showAddReturnTransactionLinesModal = true;"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <PanelReturnTransactions/>
            </v-expansion-panels>

            <v-dialog
                v-model="showAddReturnTransactionLinesModal"
                class="return-transaction-lines-modal"
                maxWidth="1200"
            >
                <v-card>
                    <v-card-title class="return-transaction-lines-modal__title">
                        <h3>Select Return Transaction to Reconcile</h3>
                        <v-spacer />
                        <v-btn
                            icon
                            @click="showAddReturnTransactionLinesModal = false"
                        >
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-stepper
                        v-model="step"
                        class="stepper"
                    >
                        <v-stepper-header>
                            <v-stepper-step step="1" class="stepper__header--first">
                                Select a Return Transaction
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="2">
                                Select Return Transaction Lines
                            </v-stepper-step>
                            <div class="stepper__header--last">
                                <span v-if="step === 1">
                                    <div class='row stepper__item'>
                                        <span class="stepper__label">Start from</span>
                                        <v-text-field
                                            v-model="returnTransactionStartDate"
                                            :disabled="inFlight || getReturnTransactionsInFlight"
                                            type='date'
                                            outlined
                                            dense
                                            hideDetails
                                            @blur="getReturnTransactionsBySupplier()"
                                        />
                                    </div>
                                </span>
                                <span v-else>
                                    <span class="stepper__label">Return Transaction Number:</span>
                                    {{ selectedReturnTransaction.transactionNumber }}
                                </span>
                            </div>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1" class="stepper__page">
                                <v-card>
                                    <v-card-text>
                                        <ReturnTransactions
                                            v-model="selectedReturnTransaction"
                                            :loading="getReturnTransactionsInFlight || inFlight"
                                            :items="filteredSupplierReturnTransactions"
                                        />
                                    </v-card-text>
                                    <v-card-actions class="stepper__actions">
                                        <v-spacer/>
                                        <v-btn
                                            outlined
                                            class="stepper__button"
                                            color="primary"
                                            @click="showAddReturnTransactionLinesModal = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            class="stepper__button"
                                            color="primary"
                                            :disabled="disabledNext"
                                            @click="step = 2;"
                                        >
                                            Next
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card>
                                    <v-card-text>
                                        <TableBaseFilterable
                                            :items="selectedReturnTransactionItems"
                                            :selected.sync="selectedReturnTransactionLines"
                                            :filter.sync="returnTransactionFilter"
                                            filterLabel="Filter Return Transaction Lines..."
                                            noDataText="No Return Transaction Lines"
                                            :headers="returnTransactionLineHeaders"
                                            :itemsPerPage="5"
                                            itemKey="rraItemId"
                                            outlined
                                            hideAdd
                                            showSelect
                                        >
                                            <template #[`item.basePrice`]="{ item }">
                                                {{ FormatUtil.toCurrency( item.basePrice ) }}
                                            </template>
                                        </TableBaseFilterable>
                                    </v-card-text>
                                    <v-card-actions class="stepper__actions">
                                        <v-spacer/>
                                        <v-btn
                                            outlined
                                            class="mr-2"
                                            color="primary"
                                            @click="step = 1"
                                        >
                                            Back
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            :disabled="selectedReturnTransactionLines.length === 0"
                                            @click="addSelectedReturnTransactionLines()"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
            </v-dialog>

        </section>
    </div>
</template>

<script>
// Vuex
import { mapState, mapGetters } from 'vuex';
import { fullNameSpace, actionTypes } from '@/store/modules/CreditMemo/Detail';

// Routes
import CreditMemoRoute from '@/router/routes/CreditMemoReconciliation';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import ObjectUtil from '@/utils/ObjectUtil';
import DateUtil from '@/utils/DateUtil';

// Constants
import CREDIT_MEMO_STATUS_LIST from '@/constants/creditMemo/creditMemoStatusList';
import returnTransactionLineHeaders from '@/constants/creditMemo/returnTransactionLineTableHeaders';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import InputCurrency from '@/components/base/inputs/InputCurrency';
import ModalConfirmation from '@/components/modals/ModalConfirmation';
import ModalUpdateError from '@/components/modals/ModalUpdateError';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import ReturnTransactionLines from './detail/ReturnTransactionLines';
import ReturnTransactions from './detail/ReturnTransactions';
import PanelReturnTransactions from './detail/PanelReturnTransactions';

export default {
    name: 'CreditMemoDetail',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        InputCurrency,
        ModalConfirmation,
        ModalUpdateError,
        ReturnTransactionLines,
        ReturnTransactions,
        TableBaseFilterable,
        PanelReturnTransactions,
    },
    beforeRouteLeave( to, from, next ) {
        if ( this.isDirty ) {
            // A browser alert is needed in order to stop the routing process
            // eslint-disable-next-line no-alert
            if ( !window.confirm( 'Leave without saving?' ) ) {
                return;
            }
        }
        next();
    },
    data() {
        return {
            // Converts the status list object into a list of objects. The text is human readable format, the value is all caps.
            // e.g. { COMPLETE: "Complete" } => [ { value: "COMPLETE", text: "Complete"} ]
            creditMemoStatusList: Object.entries( CREDIT_MEMO_STATUS_LIST ).map( ( entry ) => ( { value: entry[ 0 ], text: entry[ 1 ] } ) ),
            deleteConfirmationDialog: false,
            showHistoryModal: false,
            showUpdateErrorModal: false,
            failedToFindCreditMemoDialog: false,
            showAddReturnTransactionLinesModal: false,
            step: 1,
            isDirty: false,
            panels: [ 0 ],
            selectedReturnTransaction: {},
            selectedReturnTransactionLines: [],
            returnTransactionFilter: '',
            returnTransactionStartDate: FormatUtil.epochToDate( DateUtil.addMonths( new Date(), -6 ).getTime() ),
            prevStartDate: '',

            FormatUtil,
            returnTransactionLineHeaders,
        };
    },
    computed: {
        ...mapState( {
            inFlight: ( state ) => state.CreditMemo.Detail.inFlight,
            getReturnTransactionsInFlight: ( state ) => state.CreditMemo.Detail.getReturnTransactionsInFlight,
            info: ( state ) => state.CreditMemo.Detail.info,
            items: ( state ) => state.CreditMemo.list,
        } ),
        ...mapGetters( {
            creditMemoLinesTotal: 'CreditMemo/Detail/creditMemoLinesTotal',
            displayedCreditMemoItems: 'CreditMemo/Detail/displayedCreditMemoItems',
        } ),
        /** @returns { String } */
        headerTitle() {
            let header = this.$route.meta.title;

            if ( this.info.supplier && this.info.supplier.supplierName ) {
                header += ` - ${ this.info.supplier.supplierName }`;
            }
            if ( this.info.date ) {
                header += `, ${ FormatUtil.epochToUTCString( this.info.date ) }`;
            }
            return header;
        },
        total: {
            get() {
                return this.info.total;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setTotal }`, { total: value } );
            },
        },
        date: {
            get() {
                return this.info.date;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setDate }`, { date: value } );
            },
        },
        creditMemoId: {
            get() {
                return this.info.creditMemoId;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setCreditMemoId }`, { creditMemoId: value } );
            },
        },
        location: {
            get() {
                return this.info.location;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setLocation }`, { location: value } );
            },
        },
        creditMemoTotalUnitCount: {
            get() {
                return this.info.creditMemoTotalUnitCount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setCreditMemoTotalUnitCount }`, { creditMemoTotalUnitCount: value } );
            },
        },
        documentStatus: {
            get() {
                return this.info.documentStatus;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setDocumentStatus }`, { documentStatus: value } );
            },
        },
        notes: {
            get() {
                return this.info.notes;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setNotes }`, { notes: value } );
            },
        },
        adjustmentFees: {
            get() {
                return this.info.adjustments.FEES.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch(
                    `${ fullNameSpace }${ actionTypes.setAdjustmentFeeAmount }`,
                    { amount: value },
                );
            },
        },
        miscAdj: {
            get() {
                return this.info.adjustments.CREDIT.amount;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch(
                    `${ fullNameSpace }${ actionTypes.setAdjustmentCreditAmount }`,
                    { amount: value },
                );
            },
        },
        creditMemoItems: {
            get() {
                return this.info.creditMemoItems;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setCreditMemoItems }`, { creditMemoItems: value } );
            },
        },
        returnTransactions: {
            get() {
                return this.info.returnTransactions;
            },
            set( value ) {
                this.isDirty = true;
                this.$store.dispatch( `${ fullNameSpace }${ actionTypes.setReturnTransactions }`, { returnTransactions: value } );
            },
        },
        /**
         * Returns the supplierReturnTransactions which have return transaction items that are not associated with the credit memo
         */
        filteredSupplierReturnTransactions() {
            return this.info.supplierReturnTransactions.filter(
                // Returns true if there is at least 1 requestTransItemDto which is not in the creditMemoItems
                ( item ) => item.requestTransItemDtos.findIndex(
                    // Try and find a matching rraItemId between the 2 lists
                    ( requestTransItem ) => this.displayedCreditMemoItems
                        .findIndex( ( creditMemoItem ) => creditMemoItem.rraItemIds.includes( requestTransItem.rraItemId ) ) === -1,
                ) !== -1,
            );
        },
        selectedReturnTransactionItems() {
            if ( !this.selectedReturnTransaction || ObjectUtil.isEmpty( this.selectedReturnTransaction ) ) {
                return [];
            }
            return this.selectedReturnTransaction.requestTransItemDtos.filter(
                ( item ) => this.displayedCreditMemoItems.findIndex( ( creditMemoItem ) => creditMemoItem.rraItemIds.includes( item.rraItemId ) ) === -1,
            );
        },
        totalAdjustments() {
            return +this.adjustmentFees - +this.miscAdj;
        },
        totalAdjustmentsDisplay() {
            return FormatUtil.toCurrency( this.totalAdjustments );
        },
        displayedTotal() {
            return FormatUtil.toCurrency( this.total );
        },
        discrepancy() {
            return +this.calculatedTotal - +this.total;
        },
        discrepancyDisplay() {
            return FormatUtil.toCurrency( this.discrepancy );
        },
        calculatedTotal() {
            return FormatUtil.toCurrency( +this.creditMemoLinesTotal + +this.totalAdjustments );
        },
        disabledNext() {
            return this.getReturnTransactionsInFlight
                || this.inFlight
                || !this.selectedReturnTransaction
                || ObjectUtil.isEmpty( this.selectedReturnTransaction );
        },
    },
    beforeMount() {
        window.addEventListener( 'beforeunload', this.preventNav );
        this.$once( 'hook:beforeDestroy', () => {
            window.removeEventListener( 'beforeunload', this.preventNav );
        } );
    },
    async mounted() {
        if ( this.$route.params.id ) {
            await this.getCreditMemoInformation( this.$route.params.id );
        }
    },
    async beforeDestroy() {
        await this.$store.dispatch( 'CreditMemo/Detail/reset' );
    },
    methods: {
        async getCreditMemoInformation( creditMemoId ) {
            try {
                await this.$store.dispatch( 'CreditMemo/Detail/getCreditMemoById', { creditMemoId } );
            } catch ( error ) {
                this.failedToFindCreditMemoDialog = true;
            }
            await this.getReturnTransactionsBySupplier();
        },
        // This function prevents the browser from navigating while editing.
        preventNav( event ) {
            if ( !this.isDirty ) return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        },
        async goToSearch() {
            await this.$router.push( CreditMemoRoute.path );
        },
        async deleteCreditMemo() {
            await this.$store.dispatch( 'CreditMemo/Detail/delete' );
            await this.$router.push( CreditMemoRoute.path );
        },
        async updateInfo() {
            try {
                if ( this.isDirty ) {
                    await this.$store.dispatch( `${ fullNameSpace }${ actionTypes.update }` );
                    this.isDirty = false;
                }
            } catch ( error ) {
                this.showUpdateErrorModal = true;
            }
        },
        async addSelectedReturnTransactionLines() {
            this.showAddReturnTransactionLinesModal = false;
            this.step = 1;
            await this.$store.dispatch(
                `${ fullNameSpace }${ actionTypes.update }`,
                {
                    returnTransactionLines: this.selectedReturnTransactionLines,
                    updateFromResults: true,
                },
            );
            this.selectedReturnTransactionLines = [];
            this.selectedReturnTransaction = {};
        },
        async getReturnTransactionsBySupplier() {
            if ( this.prevStartDate !== this.returnTransactionStartDate ) {
                this.prevStartDate = this.returnTransactionStartDate;
                await this.$store.dispatch( 'CreditMemo/Detail/getReturnTransactionsBySupplier', {
                    startDate: this.returnTransactionStartDate,
                } );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/variables.scss';
.credit-memo-detail {
    margin-bottom: 5rem;
}

.detail {
    position: relative;
    margin-bottom: 1rem;
    // Space between columns
    &__section {
        padding: 1rem;

        .detail__item {
            .totals__row {
                overflow: visible;
                white-space: nowrap
            }
        }
    }
    &__delete-button {
        position: absolute;
        bottom: 0;
        right: 0;
        .delete {
            margin-right: 1.5rem;
            margin-bottom: 1rem;
        }
    }
}

.totals {
    // Prevents overlap with .detail__delete-button on small screens
    margin-bottom: 3rem;
    &__row {
        &--section-end {
            border-bottom: solid 1px;
        }
        &--difference {
            border-bottom: solid 2px;
        }
    }
    &__item {
        text-align: right;
    }
}

.return-transaction-lines-modal {
    &__title {
        color: $white;
        background-color: $primary;
    }
}

.stepper {
    &__header {
        &--first {
            margin-left: 1rem;
        }
        &--last {
            min-Width: 33.3333%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 3rem;
        }
    }
    &__actions {
        padding: .5rem 1rem;
    }
    &__item {
        display: flex;
        align-items: center;
    }
    &__label {
        margin: 0 .5rem;
    }
}

.expansion-panels {
    >:not(:last-child) {
        margin-bottom: 1rem;
    }
}
</style>
