<template>
    <TableBaseFilterable
        :items="items"
        :headers="headers"
        :filter.sync="filter"
        :loading="inFlight"
        class="custom-datatable"
        addLabel="Add Return Transaction Line"
        filterLabel="Filter Return Transaction Lines..."
        @activator:click="$emit('activator:click')"
    >
        <template #[`item.locationName`]="{ item }">
            {{ FormatUtil.locationNameFriendly( item.locationName ) }}
        </template>
        <template #[`item.basePrice`]="{ item }">
            {{ FormatUtil.toCurrency( item.basePrice ) }}
        </template>
        <template #[`item.baseTotal`]="{ item }">
            {{ FormatUtil.toCurrency( baseTotal(item) ) }}
        </template>
        <template #[`item.quantity`]="{ item }">
            <v-text-field
                :value="item.quantity"
                outlined
                hideDetails
                dense
                class="table-quantity-field"
                @input="(val) => updateQuantity( item, val )"
                @blur="updateLines()"
            />
        </template>
        <template #[`item.price`]="{ item }">
            <InputCurrency
                :value="item.price"
                class="table-price-field"
                @input="(val) => updatePrice( item, val )"
                @blur="updateLines()"
            />
        </template>
        <template #[`item.total`]="{ item }">
            {{ FormatUtil.toCurrency( total(item) ) }}
        </template>
        <template #[`item.qtyDisc`]="{ item }">
            {{ FormatUtil.toCurrency( qtyDisc(item) ) }}
        </template>
        <template #[`item.priceDisc`]="{ item }">
            {{ FormatUtil.toCurrency( priceDisc(item) ) }}
        </template>
        <template #[`item.totalDisc`]="{ item }">
            {{ FormatUtil.toCurrency( totalDisc(item) ) }}
        </template>
        <template #[`item.remove`]="{ item }">
            <v-btn
                :disabled="inFlight"
                icon
                aria-label="delete"
                @click="removeTransactionLine( item )"
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </TableBaseFilterable>
</template>

<script>
// vuex
import { mapActions, mapState, mapGetters } from 'vuex';

// components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';
import InputCurrency from '@/components/base/inputs/InputCurrency';

// utils
import FormatUtil from '@/utils/FormatUtil';

export default {
    name: 'ReturnTransactionLines',
    components: {
        TableBaseFilterable,
        InputCurrency,
    },
    props: {
        isDirty: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: 'EAN',
                    align: 'start',
                    value: 'productCode',
                },
                {
                    text: 'Title',
                    value: 'title',
                },
                {
                    text: 'Cond',
                    value: 'condition',
                },
                {
                    text: 'Location',
                    value: 'locationName',
                },
                {
                    text: 'Transaction Number',
                    value: 'transRefNum',
                },
                {
                    text: 'Unit Qty',
                    value: 'baseQuantity',
                    align: 'end',
                },
                {
                    text: 'Unit Price',
                    value: 'basePrice',
                    align: 'end',
                },
                {
                    text: 'Unit Total',
                    value: 'baseTotal',
                    align: 'end',
                },
                {
                    text: 'Qty',
                    align: 'end',
                    value: 'quantity',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                },
                {
                    text: 'Price',
                    align: 'end',
                    value: 'price',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                },
                {
                    text: 'Total',
                    align: 'end',
                    value: 'total',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                },
                {
                    text: 'Qty Discrp',
                    align: 'end',
                    value: 'qtyDisc',
                },
                {
                    text: 'Price Discrp',
                    align: 'end',
                    value: 'priceDisc',
                },
                {
                    text: 'Total Discrp',
                    align: 'end',
                    value: 'totalDisc',
                },
                {
                    text: '',
                    value: 'remove',
                },
            ],
            filter: '',
            FormatUtil,
        };
    },
    computed: {
        ...mapState( {
            inFlight: ( state ) => state.CreditMemo.Detail.inFlight,
        } ),
        ...mapGetters( {
            items: 'CreditMemo/Detail/displayedCreditMemoItems',
        } ),
        dirty: {
            get() {
                return this.isDirty;
            },
            set( val ) {
                this.$emit( 'update:isDirty', val );
            },
        },
    },
    methods: {
        ...mapActions( {
            update: 'CreditMemo/Detail/update',
        } ),
        updateQuantity( { creditMemoItemId }, quantity ) {
            this.$store.commit( 'CreditMemo/Detail/SET_CREDIT_MEMO_ITEM_QUANTITY', { creditMemoItemId, quantity: +quantity } );
            this.dirty = true;
        },
        updatePrice( { creditMemoItemId }, price ) {
            this.$store.commit( 'CreditMemo/Detail/SET_CREDIT_MEMO_ITEM_PRICE', { creditMemoItemId, price: +price } );
            this.dirty = true;
        },
        updateLines() {
            if ( this.dirty ) {
                try {
                    this.update();
                    this.dirty = false;
                } catch ( e ) {
                    throw new Error( e );
                }
            }
        },
        removeTransactionLine( { creditMemoItemId } ) {
            this.$store.commit( 'CreditMemo/Detail/REMOVE_CREDIT_MEMO_ITEM', { creditMemoItemId } );
            this.dirty = true;
            // Because dirty is modeled from the parent, value is not updated until next tick
            this.$nextTick( () => {
                this.updateLines();
            } );
        },
        qtyDisc( item ) {
            return +item.baseQuantity - +item.quantity;
        },
        priceDisc( item ) {
            return +item.basePrice - +item.price;
        },
        baseTotal( item ) {
            return +item.baseQuantity * +item.basePrice;
        },
        totalDisc( item ) {
            return this.baseTotal( item ) - this.total( item );
        },
        total( item ) {
            return +item.quantity * +item.price;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/sass/customDatatable.scss";
</style>
