<template>
    <TableBaseFilterable
        :loading="loading"
        :cardElevation="elevation"
        :headers="table.headers"
        class="return-transaction-table"
        :filter.sync="table.filter"
        :items="items"
        :itemsPerPage="10"
        :selected.sync="selected"
        noDataText="No Return Transactions"
        filterLabel="Filter Return Transactions..."
        hideAdd
        showSelect
        singleSelect
        itemKey="rraNumber"
    >
        <template #[`item.unitPrice`]="{ item }">
            {{ FormatUtil.toCurrency(item.transTotalDollarAmount / item.transTotalUnitCount) }}
        </template>
    </TableBaseFilterable>
</template>

<script>
// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'ReturnTransactions',
    components: {
        TableBaseFilterable,
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
        elevation: {
            type: String,
            required: false,
            default: '0',
        },
        value: {
            type: Object,
            required: false,
            default: () => {},
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            FormatUtil,
            table: {
                headers: [
                    {
                        text: 'Transaction Number',
                        align: 'start',
                        value: 'transactionNumber',
                    },
                    {
                        text: 'RRA Number',
                        align: 'start',
                        value: 'rraNumber',
                    },
                    {
                        text: 'Date',
                        align: 'start',
                        value: 'transCreatedDate',
                    },
                    {
                        text: 'RRA Status',
                        align: 'start',
                        value: 'rraStatus',
                    },
                    {
                        text: 'Unit Qty',
                        align: 'end',
                        value: 'transTotalUnitCount',
                    },
                    {
                        text: 'Unit Price',
                        align: 'end',
                        value: 'unitPrice',
                    },
                    {
                        text: 'Unit Total',
                        align: 'end',
                        value: 'transTotalDollarAmount',
                    },
                ],
                filter: '',
            },
        };
    },
    computed: {
        selected: {
            get() {
                return [ this.value ];
            },
            set( val ) {
                this.$emit( 'input', val[ 0 ] );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/sass/customDatatable.scss";
    .return-transaction-table {
        border: 1px solid $gray-border;
    }
</style>
