<template>
    <v-dialog
        v-model="showModal"
        class="modal"
        persistent
        maxWidth="800"
    >
        <v-card>
            <v-card-title class="headline primary white--text modal__title">
                Select Credit Memo to Reconcile
                <v-btn
                    icon
                    aria-label="Close"
                    @click="showModal = false"
                >
                    <v-icon color="white">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="modal__content">
                <div class="modal__table">
                    <TableBaseFilterable
                        class="credit-memo-lines"
                        :items="items"
                        :headers="headers"
                        :filter.sync="creditMemoLinesFilter"
                        :loading="loading"
                        filterLabel="Filter Credit Memos..."
                        addLabel="Create Credit Memo"
                        @activator:click="openCreditMemoCreate()"
                        @click:row="( e, { item } ) => openCreditMemoDetail( item )"
                    >
                        <template #[`item.date`]="{ item }">
                            {{ dateTimeToSimpleString( item.date ) }}
                        </template>
                        <template #[`item.total`]="{ item }">
                            {{ FormatUtil.toCurrency( item.total )}}
                        </template>
                    </TableBaseFilterable>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Routes
import CreditMemoRoute from '@/router/routes/CreditMemoReconciliation';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'ModalCreditMemoLines',
    components: {
        TableBaseFilterable,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            FormatUtil,
            creditMemoLinesFilter: '',
            headers: [
                {
                    text: 'Credit Memo Number',
                    value: 'creditMemoNumber',
                },
                {
                    text: 'Date',
                    value: 'date',
                },
                {
                    text: 'Quantity',
                    value: 'cmTotalUnitCount',
                    align: 'end',
                },
                {
                    text: 'Total',
                    value: 'total',
                    align: 'end',
                },
                {
                    text: 'Status',
                    value: 'documentStatus',
                },
            ],
            ...FormatUtil,
        };
    },
    computed: {
        ...mapState( {
            loading: ( state ) => state.CreditMemo.inFlight,
            items: ( state ) => state.CreditMemo.creditMemoList,
        } ),
        showModal: {
            get() {
                return this.value;
            },
            set( val ) {
                this.$emit( 'input', val );
            },
        },
    },
    methods: {
        openCreditMemoCreate() {
            this.$store.dispatch( 'CreditMemo/clearCreditMemoList' );
            this.$router.push( `${ CreditMemoRoute.path }/create` );
        },
        openCreditMemoDetail( { creditMemoId } ) {
            this.$store.dispatch( 'CreditMemo/clearCreditMemoList' );
            this.$router.push( `${ CreditMemoRoute.path }/${ creditMemoId }` );
        },
    },
};
</script>
<style scoped lang="scss">
#app .modal {
    &__title {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 2rem;
    }
    &__content {
        padding: 2rem;
        &--title {
            height: 2rem;
        }
    }
    &__button {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
