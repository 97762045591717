<template>
    <div class="sidebar-full custom-container">
        <TheSidebar fullSize>
            <SidebarFilterCreditMemos/>
        </TheSidebar>
        <ThePageHeadingBar
            :pageHeading="$route.meta.title"
            fullSize
        />
        <section class="main">
            <TableBaseFilterable
                :headers="headers"
                :items="searchResults"
                :filter.sync="searchTableFilter"
                :loading="loading"
                cardElevation="3"
                filterLabel="Filter Return Transactions..."
                hideAdd
                sortBy="transCreatedDate"
                @click:row="( e, { item } ) => openModal( item )"
            >
                <template #[`item.transCreatedDate`]="{ item }">
                    {{ FormatUtil.yearMonthDayToSimpleString( item.transCreatedDate ) }}
                </template>
                <template #[`item.location.name`]="{ item }">
                    {{ FormatUtil.locationNameFriendly( item.location.name ) }}
                </template>
                <template #[`item.priceRemainderToReconcile`]="{ item }">
                    {{ FormatUtil.toCurrency( item.priceRemainderToReconcile ) }}
                </template>
            </TableBaseFilterable>

            <ModalCreditMemoLines
                v-model="showModal"
            />
        </section>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';
import DateUtil from '@/utils/DateUtil';

// Components
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

import SidebarFilterCreditMemos from './search/SidebarFilterCreditMemos';
import ModalCreditMemoLines from './search/ModalCreditMemoLines';

export default {
    name: 'CreditMemoSearch',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        SidebarFilterCreditMemos,
        TableBaseFilterable,
        ModalCreditMemoLines,
    },
    data() {
        return {
            showModal: false,
            FormatUtil,
        };
    },
    computed: {
        ...mapState( {
            searchResults: ( state ) => state.CreditMemo.list,
            tableFilter: ( state ) => state.CreditMemo.tableFilter,
            loading: ( state ) => state.CreditMemo.inFlight,
            primarySupplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),
        searchTableFilter: {
            get() {
                return this.tableFilter;
            },
            set( val ) {
                this.$store.commit( 'CreditMemo/SET_TABLE_FILTER', { tableFilter: val } );
            },
        },
        headers() {
            return [
                {
                    text: 'Transaction Number',
                    value: 'transactionNumber',
                },
                {
                    text: 'Trans Date',
                    value: 'transCreatedDate',
                    sort: ( a, b ) => DateUtil.sortDays( a, b ),
                },
                {
                    text: 'Supplier Name',
                    value: 'supplier.supplierName',
                },
                {
                    text: 'Return Location',
                    value: 'location.name',
                },
                {
                    text: 'RRA Number',
                    value: 'rraNumber',
                },
                {
                    text: 'RRA Status',
                    value: 'rraStatus',
                },
                {
                    text: 'Total Qty',
                    value: 'transTotalUnitCount',
                    align: 'end',
                },
                {
                    text: 'Total Price',
                    value: 'transTotalDollarAmount',
                    align: 'end',
                },
                {
                    text: 'Unrecl',
                    value: 'qtyRemainderToReconcile',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                },
                {
                    text: 'Unrecl Price',
                    value: 'priceRemainderToReconcile',
                    align: 'end',
                    class: 'grouped-heading',
                    cellClass: 'grouped-cell',
                },
            ];
        },
    },
    watch: {
        showModal() {
            if ( this.showModal === false ) {
                this.$store.commit( 'CreditMemo/SET_SELECTED_TRANSACTION', {
                    selectedTransaction: {},
                } );
                this.$store.dispatch( 'CreditMemo/clearCreditMemoList' );
            }
        },
    },
    methods: {
        ...mapActions( {
            searchForCreditMemos: 'CreditMemo/getCreditMemosForRRA',
        } ),
        openModal( item ) {
            this.showModal = true;
            this.$store.commit( 'CreditMemo/SET_SELECTED_TRANSACTION', {
                selectedTransaction: {
                    ...item,
                    // Get the full supplier information
                    supplier: this.primarySupplierList.find( ( supplier ) => supplier.id === item.supplier.supplierId ),
                },
            } );
            this.searchForCreditMemos( item );
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    grid-area: main;
}
</style>
