<template>
    <v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h2>Return Transactions</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <TableBaseFilterable
                    :filter.sync="table.filter"
                    :loading="inFlight"
                    :items="returnTransactions"
                    :headers="table.headers"
                    filterLabel="Filter Return Transactions..."
                    noDataText="No associated Return Transactions"
                    hideAdd
                >
                    <template #[`item.transCreatedDate`]="{ item }">
                        {{ FormatUtil.dateTimeToSimpleString( item.transCreatedDate ) }}
                    </template>
                    <template #[`item.transTotalDollarAmount`]="{ item }">
                        {{ FormatUtil.toCurrency( item.transTotalDollarAmount ) }}
                    </template>
                </TableBaseFilterable>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panel>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'PanelReturnTransactions',
    components: {
        TableBaseFilterable,
    },
    data() {
        return {
            FormatUtil,
            table: {
                filter: '',
                headers: [
                    {
                        text: 'Transaction Number',
                        value: 'transactionNumber',
                    },
                    {
                        text: 'Transaction Date',
                        value: 'transCreatedDate',
                    },
                    {
                        text: 'Unit Qty',
                        value: 'transTotalUnitCount',
                        align: 'end',
                    },
                    {
                        text: 'Unit Price',
                        value: 'transTotalDollarAmount',
                        align: 'end',
                    },
                    {
                        text: 'RRA Number',
                        value: 'rraNumber',
                    },
                    {
                        text: 'RRA Status',
                        value: 'rraStatus',
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState( {
            inFlight: ( state ) => state.CreditMemo.Detail.inFlight,
            returnTransactions: ( state ) => state.CreditMemo.Detail.info.returnTransactions,
        } ),
    },
};
</script>
